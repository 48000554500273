<template>
  <div class="exportDataComponent">

    <b-modal
      ref="isExtractingDataModal"
      id="isExtractingDataModal"
      size="lg"
      hide-footer title="Exporter vos données"
    >
      <p class="text-dark">
        Exportez votre fichier au format Excel ou CSV
      </p>

      <b-form-group id="select-format-group"
        label="Format choisi"
        label-for="select-format-input">
        <b-form-select
          v-model="formatChosen"
          id="select-format-input"
          :options='formatSelectOptions'>
        </b-form-select>
      </b-form-group>

      <b-button
        v-if="formatChosen=='XLS'"
        block
        variant="outline-dark">
        <download-excel
            :data="data"
            :fields="fields"
            :name="name"
            :before-finish='closeExportModal'
            worksheet="Vos données"
            >
            Exporter vos données
        </download-excel>
      </b-button>

      <b-button
        v-else
        block
        variant="outline-dark">
        <download-excel
          :data="data"
          :fields="fields"
          type="csv"
          :before-finish='closeExportModal'
          :name="name">
            Exporter vos données
        </download-excel>
      </b-button>
    </b-modal>

    <b-button
      size="sm"
      variant="outline-dark"
      @click="$refs.isExtractingDataModal.show()">
      <v-icon name="download"/> {{ label }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'exportDataComponent',
  props: {
    data: Array,
    fields: Object,
    name: String,
    label: String
  },
  data () {
    return  {
      isExtractingFile: false,

      formatChosen: 'XLS',
      formatSelectOptions: [
        { text: 'Excel (.xls)', value: 'XLS' },
        { text: "Format CSV (.csv)", value: 'CSV' },
      ],
    }
  },
  methods: {
    closeExportModal () {
      this.$refs.isExtractingDataModal.hide()
    }
  }
}
</script>
