<template>
  <div class="CrawlpagesFromTags">

    <!-- ERROR MODAL -->
    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
      <div class="text-center">
        <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#F9BF3B">
    </loading>

    <formTitleComponent />
      <div class="container-fluid">
        <h1 class="form_div_title text-center">Tags</h1>
        <div class="form_div">
          <div class="form_content">

            <div class="row">
              <div class="col-sm-4 col-6 text-center">
                <strong>Liste des tags </strong>
                <div class="row mt-1" v-for="tag in tags" v-bind:key="tag.id">
                  <div class="col-12">
                    <b-button @click="chooseTag(tag)" block variant="light">
                      {{tag.name}}
                      <b-badge pill variant="warning">{{tag.nb_crawledpages}}</b-badge>
                    </b-button>
                  </div>
                </div>
              </div>

              <div class="col-sm-8 col-6">
                <exportDataComponent
                  v-if="crawlpages.length > 0"
                  :data="crawlpages"
                  :fields="exportCrawlPagesFields"
                  :name="tagChosen.name"
                  label="Exporter la sélection"
                />
                <div class="mt-2" v-for="crawlpage in crawlpages" v-bind:key="crawlpage.id">
                  <b-card class="mt-2">
                    <b-card-title>
                      {{crawlpage.title}}
                      <b-badge 
                        v-if="crawlpage.keywords"
                        pill 
                        variant="info"
                        v-b-tooltip.hover 
                        :title="crawlpage.keywords"
                      >
                        <v-icon name="info"/>
                      </b-badge>
                      <b-badge 
                        v-if="crawlpage.exclusion_keywords"
                        pill 
                        variant="danger"
                        v-b-tooltip.hover 
                        :title="crawlpage.exclusion_keywords"
                      >
                        <v-icon name="info"/>
                      </b-badge>

                    </b-card-title>
                    <b-card-sub-title><em>{{crawlpage.page.name}}</em> / {{$dayjs(crawlpage.created_at).format('DD/MM/YYYY - HH:mm')}}</b-card-sub-title>
                    <b-card-text>
                      <div class="row">
                        <div class="col-12">
                          <a :href="crawlpage.url" target="_blank"><small>{{crawlpage.url}}</small></a><br>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                          <crawlpageAddTagsComponent
                            :tags="tags"
                            :token="token"
                            :crawlpage="crawlpage"
                          />
                        </div>
                      </div>
                    </b-card-text>
                  </b-card>
                </div>
                <b-button
                  v-if="getAllCrawlpageByTagUrl && crawlpages.length > 0"
                  block variant="light" size="sm" class="mt-2"
                  @click='getAllCrawlpageByTag()'>
                    Voir plus
                </b-button>
              </div>
            </div>

          </div>
        </div>
    </div>


  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { APITag } from '@/api/APITag'
import { APICrawlPage } from '@/api/APICrawlPage'
import crawlpageAddTagsComponent from '@/components/crawlpage/crawlpageAddTagsComponent'
import exportDataComponent from '@/components/exportDataComponent'
import formTitleComponent from '@/components/formTitleComponent'

const apiTag = new APITag()
const apiCrawlPage = new APICrawlPage()

export default {
  name: 'HCrawlpagesFromTagsome',
  components: {
    crawlpageAddTagsComponent,
    exportDataComponent,
    formTitleComponent,
    Loading,
  },
  data: function () {
    return {
      tags: [],
      tagChosen: null,
      isTagsLoading: false,

      crawlpages: [],
      isCrawlPagesLoading: true,
      // ------------ GET ALL CRALPAGES BY TAGS -----------
      baseGetAllCrawlpageByTagUrl: apiBaseUrl + `/crawlpages-from-tag/`,
      getAllCrawlpageByTagUrl: apiBaseUrl + `/crawlpages-from-tag/`,

      modifyCrawlpage: null,

      exportCrawlPagesFields: {
        'Date': 'date',
        'Titre': 'title',
        'Url': 'url',
        'Detectee ?': 'is_detected',
        'Lue ?': 'is_read',
        'Titre de la page crawlée': 'page.name',
        'Url de la page crawlée': 'page.url'
      },
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isTagsLoading) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.getAllTags()
  },
  methods: {
    getAllTags () {
      this.isTagsLoading = true
      apiTag.getAllTags(this.token)
      .then((result) => {
        this.tags = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('TagList getAllTags API Error : ' + String(error))
      })
      .finally(() => {
        this.isTagsLoading = false
      })
    },

    chooseTag (tag) {
      this.getAllCrawlpageByTagUrl = this.baseGetAllCrawlpageByTagUrl + String(tag.id)
      this.crawlpages = []
      this.tagChosen = tag
      this.getAllCrawlpageByTag()
    },
    getAllCrawlpageByTag () {
      this.isCrawlPagesLoading = true
      apiCrawlPage.getAllCrawlpagesByTag(this.token, this.getAllCrawlpageByTagUrl)
      .then((result) => {
        this.crawlpages = this.crawlpages.concat(result.data.results)
        this.getAllCrawlpageByTagUrl = result.data.next
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('CrawlpagesFromTags getAllCrawlpageByTagUrl API Error : ' + String(error))
      })
      .finally(() => {
        this.isCrawlPagesLoading = false
      })
    },

    crawlpageTagged () {
      this.$refs.modifyCrawledpageTagsModal.hide()
      this.crawlpages = []
      this.getAllCrawlpageByTagUrl = this.baseGetAllCrawlpageByTagUrl + String(this.tagChosen.id)
      this.getAllCrawlpageByTag()
      this.$refs.CrawlpageTaggedModal.show()
    },

    showModifyCrawlPageTagsModal (crawlpage) {
      this.modifyCrawlpage = crawlpage
      this.$refs.modifyCrawledpageTagsModal.show()
    },

  }
}
</script>
